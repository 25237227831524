@font-path:                           "fonts";
@font-name:                           "flexslider-icon";
@font-file-name:                      "flexslider-icon";
@default-duration:                    1s;
@default-easing:                      ease;
@default-border-radius:               5px;
@default-box-shadow-x-axis:           0;
@default-box-shadow-y-axis:           1px;
@default-box-shadow-blur:             2px;
@default-box-shadow-color:            rgba( 0, 0, 0, 0.2 );
@default-box-shadow-inset:            '';
@flexslider-margin:                   0 0 60px;
@flexslider-bg:                       #fff;
@flexslider-border:                   4px solid #fff;
@flexslider-border-radius:            4px;
@flexslider-box-shadow-x-axis:        0;
@flexslider-box-shadow-y-axis:        1px;
@flexslider-box-shadow-blur:          4px;
@flexslider-box-shadow-color:         rgba( 0, 0, 0, 0.2 );
@flexslider-box-shadow-inset:         '';
@flex-viewport-max-height:            2000px;
@flex-viewport-loading-max-height:    300px;
@flex-control-thumbs:                 5px 0 0;
@flex-direction-nav-color:            rgba(0,0,0,0.8);
@flex-direction-nav-text-shadow:      1px 1px 0 rgba( 255, 255, 255, 0.3 );
@flex-direction-nav-icon-color:       rgba(0,0,0,0.8);
@flex-direction-nav-icon-text-shadow: 1px 1px 0 rgba( 255, 255, 255, 0.3 );
@flex-direction-nav-icon-prev:        '\f001';
@flex-direction-nav-icon-next:        '\f002';
@flex-pauseplay-icon-play:            '\f003';
@flex-pauseplay-icon-pause:           '\f004';