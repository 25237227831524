﻿/* Primary Layout and Container Definitions */

.wrapper {
    overflow: hidden;
}

.wrapper-content {
    padding: 40px 0;
}

.wrapper-content-tall {
    padding: 80px 0;
}

.wrapper-centered,
.content-centered {
    text-align: center;
}

.home-logo-container {
    height: 40vh;
    width: 100%;
    margin-top: 25vh;
    text-align: center;
}

.home-logo-container img {
    height: 100%;
    width: auto;
}

.wrapper-1 {
    color: #fff;
    height: 100vh;
}

.wrapper-2 {
    height: auto;
}

.wrapper-3 {
    color: #fff;
}

.wrapper-4 {
    height: 600px;
}

.parallax-container {
    position: relative;
    overflow: hidden;
}

.parallax {
    width: 100%;
    position: absolute;
    z-index: -1;
}

.parallax-background1 {
    background: url('../images/noesis-parallax/circuit-1-overlay.jpg') 50% 50% no-repeat;
}

.parallax-background2 {
    background: url('../images/noesis-parallax/noesis-parallax-green1-2000.jpg') 50% 50% no-repeat;
}

.parallax-background3 {
    background: url('../images/noesis-parallax/noesis-parallax-green2-2000.jpg') 50% 50% no-repeat;
}

.parallax-background4 {
    background: url('../images/noesis-parallax/noesis-parallax-green3-2000.jpg') 50% 50% no-repeat;
}
/* Primary Navigation */

#nav-primary {
    box-shadow: 0px 4px 15px rgba(0,0,0,0.07);
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.5s;
    -moz-transition: background 0.5s;
    -webkit-transition: background 0.5s;
    -o-transition: background 0.5s;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000;
    position: fixed;
    width: 100%;
    top: 0;
}

#nav-primary .navbar-header {
    height: 85px;
}

#nav-primary .container {
    width: 96%;
    display: block;
}

#nav-primary.transparent {
    background: transparent;
    border-bottom: 1px solid transparent;
    box-shadow: none;
}

#nav-primary.transparent a {
    color: #ffffff;
}

#nav-primary.transparent.scroll {
    background: #ffffff;
    background: rgba(255,255,255,0.95);
}

#nav-primary.transparent.scroll a {
    color: #515A5F;
}

nav .container .four.columns {
    float: left;
    width: auto;
}

nav .container .twelve.columns {
    float: right;
    width: auto;
}

.main-menu, .logo {
    height: 50px;
    display: table-cell;
    vertical-align: middle;
}

.main-menu.large, .logo.large {
    height: 80px;
}

.logo a {
    display: block;
    margin: auto;
}

.main-menu li {
    display: inline;
    margin-left: 30px;
}

.main-menu a {
    font-family: 'BebasNeueRegular', sans-serif;
    font-size: 22px;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
}

nav.light .main-menu a {
    color: inherit;
}

nav.light .main-menu a:hover, nav.dark .main-menu a:hover {
    color: #FFD600;
    transition: color 0.5s;
    -moz-transition: color 0.5s;
    -webkit-transition: color 0.5s;
    -o-transition: color 0.5s;
}

nav.light .main-menu li.active a, nav.dark .main-menu li.active a {
    color: #FFD600;
}

.main-menu a i {
    margin-right: 3px;
    font-size: 22px;
}

#nav-primary .navbar-brand {
    opacity: 0;
    transition: opacity 0.75s;
    -moz-transition: opacity 0.75s;
    -webkit-transition: opacity 0.75s;
    -o-transition: opacity 0.75s;
}

#nav-primary.scroll .navbar-brand {
    opacity: 1;
    transition: opacity 0.75s;
    -moz-transition: opacity 0.75s;
    -webkit-transition: opacity 0.75s;
    -o-transition: opacity 0.75s;
}

.navbar-toggle {
    margin-top: 22px;
}

.navbar-toggle .icon-bar {
    height: 3px;
}

@media (max-width: 767px) {
    #nav-primary .navbar-brand {
        opacity: 1;
    }

    #nav-primary.transparent {
        background: #ffffff;
        background: rgba(255,255,255,0.95);
    }

    #nav-primary.transparent li {
        text-align: center;
    }
}

/* Bootstrap Navbar Overrides */

.nav > li > a:hover,
.nav > li > a:focus {
    background-color: transparent;
}

.nav > li > a:hover {
    color: #1a4b69;
}

.main-menu li {
    margin-left: 15px;
}

@media (min-width: 768px) {
    .navbar-nav > li > a {
        padding-top: 28px;
    }
}

/* Flexslider Core */

.flexslider {
    background-color: transparent;
    border: none;
    margin: 0; /* Fix the directional nav margin */
}

.flex-direction-nav {
    *height: 0;
}

.flex-direction-nav a {
    margin: 0;
    position: relative;
    top: auto;
    opacity: 1;
}

.testimonial-slider .flex-direction-nav {
    clear: both;
    padding-top: 55px;
}

.testimonial-slider .flex-direction-nav li a {
    width: 32px;
    height: 32px;
    display: block;
    position: absolute;
    bottom: 0;
    cursor: pointer;
    text-indent: -999em;
    background-color: #FFD600;
    border-radius: 50%;
    border: 2px solid #ffffff;
    background-size: 20px;
}

.flex-direction-nav .flex-prev,
.flexslider:hover .flex-direction-nav .flex-prev {
    left: 45%;
}

.flex-direction-nav .flex-next,
.flexslider:hover .flex-direction-nav .flex-next {
    right: 45%;
    left: auto;
}

.flex-direction-nav li .flex-prev {
    background: #000000 url('@{images-path}/arrow_left.png') center center no-repeat;
    background-color: rgba(0,0,0,0.8);
}

.flex-direction-nav li .flex-next {
    background: #000000 url('@{images-path}/arrow_right.png') center center no-repeat;
    background-color: rgba(0,0,0,0.8);
}

/* Testimonials */

.testimonial-icon i {
    font-size: 22px;
    width: auto;
    color: #ffffff;
    display: inline-block;
    padding: 17px;
    background: #FFD600;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 4px #ffffff;
}

.testimonial-slide .client-testimonial {
    margin: 5px auto 10px;
    padding: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.client-info {
    margin-bottom: 20px;
}

/* Service Boxes */

.service-box {
    margin: 0 0 0px 0;
    text-align: center;
    padding: 20px 10px;
    border: 1px solid #f0f0f0;
    position: relative;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background: #ffffff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: #000000;
}

.service-box .service-icon {
    width: 128px;
    height: auto;
    font-size: 70px;
    margin: 15px auto;
    padding: 25px 20px;
    display: block;
    text-align: center;
    border-radius: 50%;
    color: #ffffff;
    border: 4px solid #ffffff;
    box-shadow: 0px 0px 0px 3px #ffd600;
    background-color: #1a4b69;
}

.service-box ul li {
    font-weight: bold;
    margin-bottom: 7px;
    padding-bottom: 7px;
    border-bottom: 1px dashed #e0e0e0;
}

.service-box:hover,
.service-box.hovered {
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.3);
    background: #FFD600;
    color: #ffffff;
    border: 1px solid transparent;
}

@media (min-width: 768px) {
    .service-description p {
        min-height: 115px;
    }
}

/* Team Photos */

.team-member .team-desc {
    padding: 10px;
    font-size: 14px;
    margin-left: -1px;
    border: 1px solid #e8e8e8;
    border-top: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    background: #ffffff;
    text-align: center;
}

.team-member:hover .team-desc {
    background: #ffd600;
    color: #ffffff;
}

.team-member .team-desc h4 {
    margin-bottom: 5px;
    font-size: 16px;
}

.team-member .team-desc h5 span {
    font-size: 12px;
    font-weight: normal;
    color: #aaa;
}

.team-member .team-desc a {
    font-weight: normal;
    display: block;
    font-size: 13px;
    margin-top: 0px;
}

.team-member .team-desc a span i {
    color: #3CF;
    margin-right: 5px;
}

.overlay-content {
    opacity: 0;
    filter: alpha(opacity=0);
    top: 30%;
}

.team-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    display: none;
    height: 100%;
    width: 100%;
}

.team-overlay .img-overlay {
    opacity: 0.8;
    filter: alpha(opacity=80);
    height: 100%;
    width: 100%;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.img-overlay {
    background: #ffffff;
}

.overlay-content {
    text-align: center;
    position: absolute;
    top: 20%;
    width: 100%;
    z-index: 10;
    color: inherit;
    font-size: 16px;
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
}

.overlay-content h4 {
    line-height: 1.4;
}

.overlay-content a {
    color: inherit;
    line-height: 1 !important;
}

.view-profile {
    padding: 4px 10px;
    background: #ffd600;
    margin-top: 4px;
    color: #ffffff !important;
    cursor: pointer;
}

.view-profile:hover {
    text-decoration: none;
}

/* Team Modal */

.modal {
    padding: 0px;
    padding-top: 5%;
}

.modal-team {
    width: auto;
    z-index: 2000;
}

.modal .close {
    color: #ffffff;
    background: #67ae3e;
    width: 40px;
    height: 40px;
    line-height: 40px;
    -webkit-border-radius: 50%;
    -mox-border-radius: 50%;
    border-radius: 50%;
    margin: 0px 0 -40px;
    text-align: center;
    opacity: 1;
}

.modal-backdrop.in {
    background-color: rgba(255,255,255,1);
    opacity: .98;
}

.modal-body {
    padding-top: 0px;
    text-align: left;
}

.modal-content {
    border: 0px;
    border-radius: 0px;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.modal-header {
    border: 0px;
    padding: 0px;
}

.member-role {
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: 1px solid #e8e8e8;
    clear: both;
    text-align: center;
}

.team-photo img {
    width: 100%;
    height: auto;
}

/* Clients */

.client-logos {
    width: 100%;
    text-align: center;
    float: left;
    padding: 20px 0;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 10px;
}

/* Contact */

.contact-details {
    width: 70%;
    padding: 5px 0;
    margin: 0 auto 10px;
}

.contact-details h2 {
    margin-bottom: 0px;
}

.contact-details h1 {
    color: black;
    background: rgba(255, 255, 255, 0.50);
    -ms-filter: ~"progid:DXImageTransform.Microsoft.gradient(startColorstr=#4Cffffff, endColorstr=#4Cffffff)";
    filter: ~"progid:DXImageTransform.Microsoft.gradient(startColorstr=#4Cffffff, endColorstr=#4Cffffff)";
    line-height: 1.5em;
    margin-top: 25px;
}

#message {
    height: 180px;
}

#contactSubmitConfirmation {
    display: none;
}

.btn-contact {
    width: 100%;
    background-image: url('images/mail.png');
    background-repeat: no-repeat;
    background-position: center center;
}

/* Social */

.social-icon {
    display: inline-block;
    margin: 0 5px 5px 0;
    background-color: #f8f8f8 !important;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    overflow: hidden;
    width: 40px;
    height: 40px;
}

.social-icon a {
    -webkit-transition: all 0.2s ease 0s;
    -moz-transition: all 0.2s ease 0s;
    -o-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
    display: inline-block;
    width: 40px;
    height: 40px;
    text-indent: -9999px;
    background-position: 0px 0px;
    background-repeat: no-repeat;
}

.social-icon a:hover {
    background-color: #444444;
    background-position: 0px -40px !important;
}

.social-icons ul {
    list-style: none;
    margin: 0 0 -1px 0;
    float: right;
}

.social-icons ul li {
    display: block;
    float: left;
    margin: 0;
    padding: 0;
}

.social-icons ul li a {
    -webkit-transition: all 0.2s ease 0s;
    -moz-transition: all 0.2s ease 0s;
    -o-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
    display: block;
    width: 40px;
    height: 40px;
    text-indent: -9999px;
    background-position: 0px 0px;
    background-repeat: no-repeat;
}

.social-icons a:hover {
    background-color: #cccccc;
    background-position: 0px -40px !important;
    opacity: 1;
}

.social-email a {
    background: url('@{images-path}/icons/social/email.png') no-repeat 0 0;
}

.social-email a:hover {
    background-color: #666666;
}

.social-facebook a {
    background: url('@{images-path}/icons/social/facebook.png') no-repeat 0 0;
}

.social-facebook a:hover {
    background-color: #3b5998;
}

.social-github a {
    background: url('@{images-path}/icons/social/github.png') no-repeat 0 0;
}

.social-github a:hover {
    background-color: #7292a2;
}

.social-googleplus a {
    background: url('@{images-path}/icons/social/googleplus.png') no-repeat 0 0;
}

.social-googleplus a:hover {
    background-color: #d94a39;
}

.social-linkedin a {
    background: url('@{images-path}/icons/social/linkedin.png') no-repeat 0 0;
}

.social-linkedin a:hover {
    background-color: #71b2d0;
}

/* Misc */

.row.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
}

.row.no-gutters img {
    width: 100%;
    margin: 0;
}

.form-website {
    display: none;
}

/* Responsive */

@media (max-width: 767px) {
    .wrapper-1 {
        height: auto;
    }

    .home-logo-container {
        width: 80vw;
        margin: auto;
        height: auto;
        margin-top: 125px; /* 85 navbar + 40px */
        margin-bottom: 40px;
    }

    .home-logo-container img {
        width: 100%;
        height: auto;
    }

    .wrapper-3 {
        background-size: cover;
    }

    .wrapper-content-tall {
        padding: 40px 0;
    }

    .service-box {
        margin-bottom: 20px;
    }

    .service-description {
        margin-bottom: 31px;
        margin-top: 30px;
        padding: 0 30px;
    }

    .flex-direction-nav .flex-prev,
    .flexslider:hover .flex-direction-nav .flex-prev,
    .github-slider .flex-direction-nav li .flex-prev {
        left: 35%;
    }

    .flex-direction-nav .flex-next,
    .flexslider:hover .flex-direction-nav .flex-next,
    .github-slider .flex-direction-nav li .flex-next {
        right: 35%;
        left: auto;
    }

    .github-slider {
        width: 100%;
    }
}