﻿/* ------------------------------------------------------------------------ */
/*  Github Slider
/* ------------------------------------------------------------------------ */

.github-slider {
	position: relative;
	width: 75%;
	margin: 0 auto !important;
}

.github-feed-item {
	text-align: left;
	font-size: 1.5em;
}

.github-slide {
	padding: 20px !important;
}

.github-slider ul.slides {
	min-height: 250px;
	background-color: rgba(26, 75, 105, 0.85);
	border-top: 1px solid white;
	border-bottom: 1px solid white;
}

.github-slider .flex-direction-nav li a {
	width: 32px;
	height: 32px;
	display: block;
	position: absolute;
	bottom: 0;
	cursor: pointer;
	text-indent: -999em;
	background-color: #FFD600;
	border-radius: 50%;
	border: 2px solid #ffffff;
	background-size: 20px;
}

.github-slider .flex-direction-nav li .flex-prev {
	left: 45%;
}

.github-slider .flex-direction-nav li .flex-next {
	right: 45%;
	left: auto;
}

.github-slider > ul {
	text-align: center;
	padding: 10px 0;
}

.github-slider .flex-control-nav {
	display: none;
}

.github-slider .flex-direction-nav {
	clear: both;
	padding-top: 55px;
}

.gha-feed {
	width: 100%;
	height: 100%;
	background: #fff;
	font-weight: bold;
	font-size: 14px;
	font-family: Helvetica, arial, freesans, clean, sans-serif;
	line-height: 1.3;
	overflow-y: auto;
	border: 1px solid #ddd;
}

.gha-feed, .gha-feed h2, .gha-feed h3, .gha-feed p, .gha-feed ul, .gha-feed li {
	margin: 0;
	padding: 0;
}

.gha-feed ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.gha-feed li {
	list-style-type: none;
	line-height: 1.4;
}

.gha-feed small {
	color: #666;
	font-weight: normal;
	font-size: 13px;
}

.gha-feed small a {
	font-weight: normal;
}

.gha-feed small a .more-commits {
	font-size: 11px;
}

span.gha-time {
	color: #bbb;
	font-weight: normal;
	font-size: 12px;
}

.gha-feed a {
	color: #4183c4;
	text-decoration: none;
	font-weight: bold;
}

.gha-feed a:hover {
	text-decoration: underline;
}

.gha-feed pre {
	padding: 0;
	border: 0;
	border-radius: 0;
	box-shadow: 1px 1px 4px #bbb;
	color: white;
}

.gha-github-icon {
	display: inline;
	float: left;
	padding: 9px 0 0;
	width: 35px;
	height: 35px;
	color: #495961;
}

.gha-github-icon .octicon {
	font: normal normal 40px octicons;
}

.gha-gravatar {
	display: inline;
	float: right;
	margin-right: 10px;
	padding-right: 20px;
	max-width: 60px;
	height: 67px;
}

.gha-gravatar img {
	padding: 3px;
	width: 100%;
	border: 1px solid #ddd;
	box-shadow: 1px 1px 3px #ccc;
}

.gha-activity {
	clear: both;
	padding: 10px 0;
	width: 100%;
	border-bottom: none;
}

.gha-activity.gha-small {
	margin-top: 5px;
	font-weight: normal;
	font-size: 13px;
}

.gha-activity.gha-small a {
	font-weight: normal;
}

.gha-activity.gha-small .gha-message {
	float: left;
	width: auto;
	margin-right: 5px;
	margin-top: 5px;
}

.gha-activity.gha-small span {
	font-size: 16px;
}

.gha-activity.gha-small .gha-time {
	float: left;
	margin-top: 6px;
}

.gha-activity:last-child {
	padding-bottom: 100px;
}

.gha-repo {
	clear: both;
	padding: 10px 0;
	width: 100%;
	border-bottom: 1px solid #ddd;
}

.gha-activity-icon .octicon {
	display: inline;
	float: left;
	clear: both;
	margin: 6px auto;
	width: 50px;
	color: #bbb;
	text-align: center;
	font: normal normal 30px octicons;
}

.gha-activity-icon .gha-small {
	font-size: 16px;
}

.gha-message {
	display: inline-block;
	float: left;
	width: 100%;
	font-size: 20px;
	font-family: 'Oswald', sans-serif;
}

.gha-commits small, .gha-message small {
	font-family: 'Open Sans', 'Times New Roman', Times, serif;
}

.gha-message-commits {
	font-size: 14px;
}

.gha-message-merge {
	padding: 3px 7px;
	border-radius: 3px;
	background: #e8f1f6;
	color: rgba(0,0,0,0.5);
	font-size: 12px;
	line-height: 2.0;
}

.gha-sha {
	font-size: 14px;
	font-family: Consolas, "Liberation Mono", Courier, monospace;
}

.gha-gravatar-commit, .gha-gravatar-small {
	margin-bottom: -3px;
	border-radius: 2px;
	width: 24px !important;
	height: 24px !important;
	display: inline-block !important;
}

.gha-gravatar-small {
	margin-right: 5px;
}

.gha-gravatar-user {
	float: left;
}

.gha-user-info {
	display: inline-block;
	float: left;
	margin: 0 auto;
	padding: 6px 10px 5px;
	color: #495961;
	font-size: 20px;
}

.gha-user-info a {
	color: #495961;
}

.gha-user-info p a {
	font-weight: 100;
}

.gha-without-name {
	padding-top: 20px;
	padding-left: 15px;
}

.gha-info {
	margin: 15px;
	padding: 10px;
	border: 1px solid #e4e4c6;
	border-radius: 4px;
	background: #ffffde;
	color: #6d6d4b;
	font-weight: normal;
	font-size: 13px;
}

.gha-time {
	color: #bbb;
	font-weight: normal;
	font-size: 12px;
}

.gha-clear {
	clear: both;
}

.gha-muted {
	color: #666;
}

.gha-push {
	height: 87px;
}

.gha-push-small {
	height: 26px;
}