﻿/* General */

.wrapper.grey {
	background-color: #f5f5f5;
}

/* Desktop Navbar */
.navbar-toggle {
	border-color:#fff;
}

.icon-bar {
	background-color:#fff;
}

/* Noesis Green */

a,
#nav-primary.scroll li.active a,
.service-box:hover .service-icon,
.service-box.hovered .service-icon,
.highlight {
	color:#67ae3e;
}

.team-member:hover .team-desc {
	background: #67ae3e;
	color: #ffffff;
}

/* Noesis Blue */

.service-box:hover,
.service-box.hovered,
.service-box .service-icon,
.testimonial-icon i,
.testimonial-slider .flex-direction-nav li a,
.github-slider .flex-direction-nav li a,
.view-profile,
.scroll .icon-bar,
.wrapper-footer {
	background-color: #1a4b69;
}

.scroll .navbar-toggle {
	border:1px solid #1a4b69;
}

@media (max-width: 767px) {
	.icon-bar {
		background-color: #1a4b69;
	}

	.navbar-toggle {
		border:1px solid #1a4b69;
	}

	#nav-primary.transparent a {
		color:#1a4b69;
	}

	#nav-primary.transparent a {
		border-top:1px solid #ddd;
	}

}





/* Titles */

.title h1 {
	box-shadow: 0px 0px 0px 3px #1a4b69;
	background-color: #1a4b69;
	color:#FFF;
}

/* Service Boxes */

.service-box .service-icon {
	box-shadow: 0px 0px 0px 3px #1a4b69;
}

.service-box:hover .service-icon,
.service-box.hovered .service-icon {
	background:#FFF;
	box-shadow: 0px 0px 0px 3px #ffffff;
	border-color: #1a4b69;
}