﻿/* General */

h1, h2, h3, h4, h5, h6 {
	text-transform: uppercase;
	font-weight: 400;
	margin-bottom: 10px;
	margin-top:0;
}

h1 {
	font-family: 'BebasNeueRegular', sans-serif;
	font-size: 80px;
	text-transform: uppercase;
}

h2 {
	font-family: 'BebasNeueRegular', sans-serif;
	font-size: 44px;
	text-transform: uppercase;
}

h3 {
	font-family: 'BebasNeueRegular', sans-serif;
	font-size: 30px;
}

h4 {
	font-family: 'Oswald', sans-serif;
	font-size: 22px;
}

h6 {
	font-family: 'Oswald', sans-serif;
	font-size: 18px;
}

ul {
	list-style: none;
}

ul.styled-list {
	padding: 0;
}

/* Titles */

.title h1 {
	font-family: "Open Sans", Arial, sans-serif;
	font-weight: 400;
	text-transform: none;
	padding: 20px;
	font-size: 64px;
	margin-bottom: 30px;
	display:inline-block;
	border: 4px solid #ffffff;
	line-height:0.8em;
	margin-top:4px /* Fix the border vertical centering */
}

.subtitle {
	text-transform: none;
	font-weight: normal;
	font-size: 22px;
	line-height: 1.3;
	margin-bottom: -0.65em;
}

/* Navigation */

#nav-primary li a {
	font-family: 'BebasNeueRegular', sans-serif;
	font-size: 22px;
	color: white;
	text-decoration: none;
	text-transform: uppercase;
}

/* Misc Content */

.testimonial-slide .client-testimonial {
	font-size: 20px;
	line-height: 1.8;
	font-family: 'Oswald', sans-serif;
	color: white;
}

.client-info {
	color: #ffffff;
	font-size: 14px;
	font-weight: 600;
	font-style: italic;
}

.member-role h4 {
	line-height: 1;
	font-size: 26px;
}

/* Footer */

.wrapper-footer {
	color:#FFF;
}

/* Fonts */

@font-face {
	font-family: 'BebasNeueRegular';
	src: url('../fonts/BebasNeue-webfont.eot');
	src: url('../fonts/BebasNeue-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/BebasNeue-webfont.woff') format('woff'), url('../fonts/BebasNeue-webfont.ttf') format('truetype'), url('../fonts/BebasNeue-webfont.svg#BebasNeueRegular') format('svg');
	font-weight: normal;
	font-style: normal;
}

/* Responsive */

@media (max-width: 767px) {
	.title h1 {
		font-size:36px;
	}

	.subtitle  {
		font-size:18px;
	}

	.testimonial-slide .client-testimonial {
		font-size:18px;	
	}

	.gha-message {
		font-size:16px;
	}

	.contact-details h2 {
		font-size:26px;
	}

	.contact-details h1 {
		font-size:48px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	#nav-primary li a { 
		font-size:20px;
		line-height:30px;

	}
}