/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/


.flex-container,
.flex-slider {

  a:hover {

    outline: none;

  }

}

.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav {

  margin: 0;
  padding: 0;
  list-style: none;

}

.flex-pauseplay span {

  text-transform: capitalize;

}